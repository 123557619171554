<template>
  <div>
    <b-button
      v-if="allows_crud"
      variant="primary"
      size="sm"
      class="mt-1"
      @click="createdOption()"
    >
      + Agregar alternativa
    </b-button>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { toast } from "@/utils/utils";
import { mapGetters } from "vuex";
export default {
  name: "FillingQuestion",
  components: {},
  props: {
    Question: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
    preview_evaluation: {
      type: Boolean,
      required: true,
    },
    evaluatee_view: {
      type: Boolean,
      default: false,
    },
    is_running_test: {
      type: Boolean,
      default: false,
    },
    finished_test: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      filling_question_options: names.NEW_FILLING_QUESTION_OPTIONS,
      filling_question_sub_types: names.NEW_FILLING_QUESTION_SUB_TYPES,
    }),
    fillingQuestionOptions() {
      return this.filling_question_options.filter(
        (x) => x.filling_question == this.Question.id
      );
    },
    optionsList: {
      get() {
        return [...this.fillingQuestionOptions].sort(
          (a, b) => a.order - b.order
        );
      },
      set(list) {
        list.forEach((element, index) => {
          if (!isNaN(element.id)) {
            this.patchOption(element.id, { order: index + 1 });
          }
        });
      },
    },
  },
  methods: {
    createdOption() {
      if (this.optionsList.length > 0)
        this.$store.dispatch(names.POST_NEW_FILLING_QUESTION_OPTION, {
          text: "",
          correct_position: null,
          score: 0,
          order: this.optionsList[this.optionsList.length - 1].order + 1,
          filling_question: this.Question.id,
        });
      else
        this.$store.dispatch(names.POST_NEW_FILLING_QUESTION_OPTION, {
          text: "",
          correct_position: null,
          score: 0,
          order: 1,
          filling_question: this.Question.id,
        });
      toast("Se agregó una alternativa");
    },
    patchOption(option_id, item) {
      const payload = {
        new_filling_question_option_id: option_id,
        item: item,
      };
      this.$store.dispatch(names.PATCH_NEW_FILLING_QUESTION_OPTION, payload);
      toast("Se modificó la alternativa");
    },
  },
  created() {},
};
</script>

<style scoped>
</style>

